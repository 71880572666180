import { default as _400bZQ9wmyZFZMeta } from "/usr/src/nuxt-app/pages/400.vue?macro=true";
import { default as _404Am7IRVzZLlMeta } from "/usr/src/nuxt-app/pages/404.vue?macro=true";
import { default as _500G6FYxrlp1HMeta } from "/usr/src/nuxt-app/pages/500.vue?macro=true";
import { default as aboutqNk3HSzUMDMeta } from "/usr/src/nuxt-app/pages/about.vue?macro=true";
import { default as contactys44YCxHo7Meta } from "/usr/src/nuxt-app/pages/contact.vue?macro=true";
import { default as indexAlKKXJi8jwMeta } from "/usr/src/nuxt-app/pages/courses/[id]/index.vue?macro=true";
import { default as helpyMZjyZoaSzMeta } from "/usr/src/nuxt-app/pages/help.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as indexFr9SG4UmJ0Meta } from "/usr/src/nuxt-app/pages/kategori/index.vue?macro=true";
import { default as kebijakan_45privasi3YppW17TppMeta } from "/usr/src/nuxt-app/pages/kebijakan-privasi.vue?macro=true";
import { default as ketentuan_45layanan6SwS27EAqWMeta } from "/usr/src/nuxt-app/pages/ketentuan-layanan.vue?macro=true";
import { default as certificatetsPIFKFNHpMeta } from "/usr/src/nuxt-app/pages/kursus/[slug]/certificate.vue?macro=true";
import { default as indexnaUqBzHvFQMeta } from "/usr/src/nuxt-app/pages/kursus/[slug]/index.vue?macro=true";
import { default as materiEUAUJnQxBWMeta } from "/usr/src/nuxt-app/pages/kursus/[slug]/materi.vue?macro=true";
import { default as index9eM5UYaVrDMeta } from "/usr/src/nuxt-app/pages/profil/form/index.vue?macro=true";
import { default as indexDypmjvR0HYMeta } from "/usr/src/nuxt-app/pages/profil/index.vue?macro=true";
import { default as pusat_45bantuanjieEWTPEjWMeta } from "/usr/src/nuxt-app/pages/pusat-bantuan.vue?macro=true";
import { default as reset_45emailsjv5ea7BiMMeta } from "/usr/src/nuxt-app/pages/reset-email.vue?macro=true";
import { default as indexgceGhax48pMeta } from "/usr/src/nuxt-app/pages/reset-password/index.vue?macro=true";
import { default as success2TR5GAVatAMeta } from "/usr/src/nuxt-app/pages/reset-password/success.vue?macro=true";
import { default as indexxrMQAuuCc9Meta } from "/usr/src/nuxt-app/pages/riwayat-transaksi/index.vue?macro=true";
import { default as searchiOAa32tjZNMeta } from "/usr/src/nuxt-app/pages/search.vue?macro=true";
import { default as signindVFe89Mb1OMeta } from "/usr/src/nuxt-app/pages/signin.vue?macro=true";
import { default as signuppd0Tj8SUdWMeta } from "/usr/src/nuxt-app/pages/signup.vue?macro=true";
import { default as indexAeP6eqxFklMeta } from "/usr/src/nuxt-app/pages/studi-saya/index.vue?macro=true";
import { default as indexS8IJxfIoRRMeta } from "/usr/src/nuxt-app/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: "400",
    path: "/400",
    component: () => import("/usr/src/nuxt-app/pages/400.vue")
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/usr/src/nuxt-app/pages/404.vue")
  },
  {
    name: "500",
    path: "/500",
    component: () => import("/usr/src/nuxt-app/pages/500.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/usr/src/nuxt-app/pages/about.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/usr/src/nuxt-app/pages/contact.vue")
  },
  {
    name: "courses-id",
    path: "/courses/:id()",
    meta: indexAlKKXJi8jwMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/courses/[id]/index.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/usr/src/nuxt-app/pages/help.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/nuxt-app/pages/index.vue")
  },
  {
    name: "kategori",
    path: "/kategori",
    component: () => import("/usr/src/nuxt-app/pages/kategori/index.vue")
  },
  {
    name: "kebijakan-privasi",
    path: "/kebijakan-privasi",
    component: () => import("/usr/src/nuxt-app/pages/kebijakan-privasi.vue")
  },
  {
    name: "ketentuan-layanan",
    path: "/ketentuan-layanan",
    component: () => import("/usr/src/nuxt-app/pages/ketentuan-layanan.vue")
  },
  {
    name: "kursus-slug-certificate",
    path: "/kursus/:slug()/certificate",
    meta: certificatetsPIFKFNHpMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/kursus/[slug]/certificate.vue")
  },
  {
    name: "kursus-slug",
    path: "/kursus/:slug()",
    component: () => import("/usr/src/nuxt-app/pages/kursus/[slug]/index.vue")
  },
  {
    name: "kursus-slug-materi",
    path: "/kursus/:slug()/materi",
    meta: materiEUAUJnQxBWMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/kursus/[slug]/materi.vue")
  },
  {
    name: "profil-form",
    path: "/profil/form",
    meta: index9eM5UYaVrDMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/profil/form/index.vue")
  },
  {
    name: "profil",
    path: "/profil",
    meta: indexDypmjvR0HYMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/profil/index.vue")
  },
  {
    name: "pusat-bantuan",
    path: "/pusat-bantuan",
    component: () => import("/usr/src/nuxt-app/pages/pusat-bantuan.vue")
  },
  {
    name: "reset-email",
    path: "/reset-email",
    component: () => import("/usr/src/nuxt-app/pages/reset-email.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/usr/src/nuxt-app/pages/reset-password/index.vue")
  },
  {
    name: "reset-password-success",
    path: "/reset-password/success",
    component: () => import("/usr/src/nuxt-app/pages/reset-password/success.vue")
  },
  {
    name: "riwayat-transaksi",
    path: "/riwayat-transaksi",
    meta: indexxrMQAuuCc9Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/riwayat-transaksi/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/usr/src/nuxt-app/pages/search.vue")
  },
  {
    name: "signin",
    path: "/signin",
    component: () => import("/usr/src/nuxt-app/pages/signin.vue")
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/usr/src/nuxt-app/pages/signup.vue")
  },
  {
    name: "studi-saya",
    path: "/studi-saya",
    meta: indexAeP6eqxFklMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/studi-saya/index.vue")
  },
  {
    name: "wishlist",
    path: "/wishlist",
    meta: indexS8IJxfIoRRMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/wishlist/index.vue")
  }
]