import revive_payload_client_4sVQNw7RlN from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/usr/src/nuxt-app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/nuxt-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_HQNyLQlVwt from "/usr/src/nuxt-app/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import plugin_client_LcKgStRyi6 from "/usr/src/nuxt-app/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_ghbUAjaD3n from "/usr/src/nuxt-app/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import templates_pwa_client_1ed816b6_f1fJtDUutC from "/usr/src/nuxt-app/.nuxt/templates.pwa.client.1ed816b6.ts";
import nuxt_plugin_6wEQMY3tee from "/usr/src/nuxt-app/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import dompurify_html_VcfsMfUvBB from "/usr/src/nuxt-app/plugins/dompurify-html.ts";
import error_ldt3PQauZ0 from "/usr/src/nuxt-app/plugins/error.ts";
import index_jaNa8AfV8H from "/usr/src/nuxt-app/plugins/helper/index.ts";
import hotjar_client_HJG8no80GI from "/usr/src/nuxt-app/plugins/hotjar.client.ts";
import sentry_client_shVUlIjFLk from "/usr/src/nuxt-app/plugins/sentry.client.ts";
import social_sharing_ntpaD0Rjds from "/usr/src/nuxt-app/plugins/social-sharing.ts";
import whiteSpace_hg0xEctbxj from "/usr/src/nuxt-app/plugins/whiteSpace.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_HQNyLQlVwt,
  plugin_client_LcKgStRyi6,
  plugin_ghbUAjaD3n,
  templates_pwa_client_1ed816b6_f1fJtDUutC,
  nuxt_plugin_6wEQMY3tee,
  dompurify_html_VcfsMfUvBB,
  error_ldt3PQauZ0,
  index_jaNa8AfV8H,
  hotjar_client_HJG8no80GI,
  sentry_client_shVUlIjFLk,
  social_sharing_ntpaD0Rjds,
  whiteSpace_hg0xEctbxj
]