<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    type?: string | number
  }>(),
  {
    type: 404
  }
)

const { type } = toRefs(props)
const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <div class="kg error-wrapper">
    <div class="error-content">
      <div class="error-content__title">
        {{
          +type === 404
            ? 'Halaman Tidak Dapat Ditemukan'
            : 'Maaf, terjadi kesalahan teknis'
        }}
      </div>
      <div class="error-content__description">
        {{
          +type === 404
            ? 'Halaman yang kamu coba akses tidak tersedia. Silakan kembali ke halaman utama atau gunakan fitur pencarian untuk menemukan informasi lainnya.'
            : 'Saat ini ada masalah dengan server kami. Kami sedang menanganinya, silahkan coba beberapa saat lagi'
        }}
      </div>
      <a-button v-if="+type === 404" type="primary" @click="handleError">
        Kembali ke Homepage
      </a-button>
    </div>
    <div class="error-image">
      <img
        v-if="+type === 404"
        src="~/assets/images/error/404.svg"
        loading="lazy"
        alt="Kognisi page not found"
      />
      <img
        v-else
        src="~/assets/images/error/500.svg"
        loading="lazy"
        alt="Kognisi 500"
      />
    </div>
  </div>
</template>

<style lang="scss">
@import './styles.scss';
</style>
